@import "../../components/scss/variables.scss";

#job-group {
    width: 100%;
    padding: 2rem 0 5rem 0;

    .spinner {
        margin-top: 2.5rem;
        text-align: center;
        img {
            animation: spin 1s linear infinite;
            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }

    ul {
        max-width: 1440px;
        margin: 0 auto;
        color: $textWhite;
        list-style: none;
        padding: 1rem 0 1rem 1rem;
        @media screen and (max-width: $tablet) {
            padding: 1rem 0 0 0;
        }

        li {
            cursor: pointer;
            background: #383e4a;
            text-align: left;
            border-radius: 15px;
            margin-bottom: 1rem;
            padding: 0.05rem 1.75rem;
            width: 100%;
            &:hover {
                background: #4f5560;
            }
            @media screen and (max-width: $mobile) {
                padding: 0.05rem 1rem;
                width: 100%;
            }

            .title {
                @include TTCommon();
                font-size: 1.25rem;
                color: #fefefe;
                margin-bottom: 0;
            }
            .content {
                @include Hind();
                font-size: 1em;
                color: #fefefe;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-top: 0.25rem;
            }
        }
    }
}
.not-eligible {
    @include Hind();
    font-size: 1.25rem;
    color: #fefefe;
    text-align: center;
    margin: 1rem 0;
}
