@import './variables.scss';


.react-tabs {
    -webkit-tap-highlight-color: transparent;


    .react-tabs__tab-list {
        max-width: 1440px;
        margin: 0 auto;
        margin-top: -40px;
        bottom:0;
        border-bottom: none;
        text-align: right;
        @media screen and ( max-width: $largeTablet){
        text-align: center;
         }
       }
    .react-tabs__tab {
        display: inline-block;
        border: none;
        bottom: 20px;
        position: relative;
        list-style: none;
        @include TTCommon();
        font-weight: 600;
        font-size: 1.25rem;
        color:$textWhite;;
        padding: 0.5rem 1rem;
 
        cursor: pointer;

        @media screen and ( max-width: $largeTablet){
         padding: 0.5rem;
         bottom: 15px;
        }
        @media screen and ( max-width: $mobile){
            padding: 0.5rem;
           }
        &:hover{
            color:#01CBB7;
        }
        &::after{
          content: "";
          display: block;
          width: 5rem;
          margin: 0 auto;
          padding-bottom: 12px;
          border-bottom: 2px solid transparent;
      }
   
    }
  
    .react-tabs__tab--selected {
        background: none;
        color:#01CBB7;
       &::after{
        border-color: white;
    }
     
    }
  
  .react-tabs__tab:focus {
    outline: none;
    color:#01CBB7;
        &::after{
            content: "";
            display: block;
            width: 5rem;
            margin: 0 auto;
      }
    }
  
  .react-tabs__tab:focus:after {
    position: absolute;
    height: 0;
    left: 0;
    right: 0;
    bottom: 20;
    background: transparent;
  }
}

#tab-bg{
    background: linear-gradient(135deg, #01CBB7 0%, #00796D 60.42%, #00655B 100%) ;
     width: 100%;

    .react-tabs__tab-panel {
        display: none;
      }
      
      .react-tabs__tab-panel--selected {
        display: block;
        max-width: 1440px;
        margin: 0 auto;
        padding: 2rem 1rem ;
        span{
            @include Hind();
            font-weight: 700;
            font-size: 1.125rem;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            color: #00655B;
            letter-spacing: 0.15em;
        }
        h1{
            @include TTCommon();
            font-size: 3.125rem;
            color: $textWhite;
            margin: 0;
        }
   
       .description-box{
            display: flex;
            justify-content: space-between;
            @media screen and ( max-width: $largeTablet){
              display:table;
           }
 
            p{
                @include Hind();
                font-weight: 700;
                font-size: 1.125rem;
                color: $textWhite;
                width: 60%;
                padding-top: 2rem;
                @media screen and ( max-width: $largeTablet){
                   width: 90%;
                   padding-top:0;
                }    
            }
            .sub-box{
             width: 40%;
             max-width: 300px;
             @media screen and ( max-width: $largeTablet){
                 width: 90%;
                 max-width: none;
          
              }
              h5{
                @include Hind();
                font-weight: 500;
                font-size: 1rem;
                color: $textWhite;
            }
            .secondary-btn{
                @include secondary-btn();
                float: left;
              }
            }
    
        }
      }
  }