@import "./variables.scss";

header {
  background: $bgDark;
}

.nav {
  max-width: 1440px;
  padding: 2.5rem 2rem;
  display: flex;
  @media screen and (max-width: $largeTablet) {
    display: grid;
    padding: 3rem 0 1rem 1.5rem;
  }
  @media screen and (max-width: $mobile) {
    padding: 1rem 0 0 0;
  }
  .logo-link {
    width: 30%;
    @media screen and (max-width: $largeTablet) {
      width: 100%;
    }
    .ux-brand {
      display: flex;
      justify-content: start;
      padding: 0 1rem;

      img {
        width: 100%;
        max-width: 155px;
        object-fit: contain;
        @media screen and (max-width: $mobile) {
          max-width: 100px;
        }
      }
      p {
        @include OpenSans();
        font-size: 2.5rem;
        letter-spacing: 4px;
        color: #ffffff;
        margin-left: -3px;
        margin-top: 3.75rem;
        @media screen and (max-width: $mobile) {
          font-size: 2.25rem;
          margin-top: 3rem;
        }
      }
    }
  }

  .nav-text {
    width: 70%;
    margin: 0 0 0 5rem;
    text-align: left;
    max-width: 700px !important;
    @media screen and (max-width: $largeTablet) {
      width: 90%;
      margin: 0;
    }

    h1 {
      @include TTCommon();
      font-size: 2.26rem;
      color: #ffffff;
    }
    p {
      @include Hind();
      font-weight: 500;
      font-size: 1.125rem;
      color: #ffffff;
    }
  }
}

/**detail page navigation*/
.sub-nav {
  max-width: 1440px;
  padding: 2rem;
  margin: 0 auto;

  .ux-brand {
    max-width: 150px;
    @media screen and (max-width: $largeTablet) {
      justify-content: center;
    }
    .logo-link {
      display: flex;
      justify-content: start;

      img {
        width: 55px !important;
        object-fit: contain;
      }
      p {
        @include OpenSans();
        font-size: 1.125rem;
        letter-spacing: 2px;
        color: #ffffff;
        max-width: 60px;
      }
    }
  }
}
