@import "../../components/scss/variables.scss";

.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .modal button {
    outline: none;
    cursor: pointer;
    border: 0;
  }

#modal-section{
    background: #1C222E !important;
    box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.05), 0px 5px 22px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    max-width: 750px;
    margin: 0 auto;
    padding: 2rem;
    animation: modal-show 0.5s;
    overflow: hidden;
    @media screen and ( max-width: $largeTablet){
        width: 70%;
       }
    @media screen and ( max-width: $mobile){
    padding: 1rem;
    }
  }

#modal-section header {
    position: relative;
    padding: 0.5rem 10rem 3rem 10rem;
    display: flex;
    justify-content: center;
    @media screen and ( max-width: $tablet){
        padding: 0.5rem 5em 3rem 5rem;
       }
    @media screen and ( max-width: $mobile){
        width: 30%;
        margin: 0 auto;
    }    
      .logo{
          width: 100%;
          max-width: 155px;
          object-fit: contain;
      }
       .logoText{
           font-family: 'Open Sans';
           font-size: 2.5rem;
           font-weight: 400;
           letter-spacing: 4px;
           color: #FAFAFA;
          padding-top: 10px;
          margin-left: -8px;
          line-height: 1;
          text-align: left;
          white-space:normal;
          @media screen and ( max-width: $mobile){
            font-size: 2rem;
            }  
         }
         button.close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 30px;
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
            color: #999;
            background-color: transparent;
          }
   }

#modal-main { 
   text-align: center;

   form{
       @media screen and ( max-width: $tablet){
       width: 90%;
       margin: 0;
        }        
    .form-group{
        margin-bottom: 2.5rem;

        .input{
            width: 100%;
            max-width: 520px;
            height: 58px;
            padding-left: 1rem;
            background: #4E535E !important;
            border: none;
            border-radius: 13px;
            font-size: 1.25rem;
            color:$textWhite;
            &:active, &:focus{
                outline:none;
            }
        }
        ::placeholder {
            color: #FEFEFE;
            opacity: 1; 
            @include Hind();
            font-size: 1.25rem;
          }
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
           color: #FEFEFE;
           @include Hind();
           font-size: 1.25rem;
          }
          ::-ms-input-placeholder { /* Microsoft Edge */
           color: #FEFEFE;
           @include Hind();
           font-size: 1.25rem;
          }
        }
     }
    }

#modal-section footer {
    margin: 0 auto;
    max-width: 520px;
    padding-bottom: 4rem;
   @media screen and ( max-width: $tablet){
    padding: 0.5rem;
        }     
  }
#modal-section footer button {
   @include secondary-btn();
  }
  .modal.openModal {
    display: flex;
    align-items: center;
    animation: modal-bg-show 0.3s;
  }


  @keyframes modal-show {
    from {
      opacity: 0;
      margin-top: -50px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }
  @keyframes modal-bg-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }