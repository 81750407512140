@import "../../components/scss/variables.scss";
@import "../home/main.scss";

#teampage {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .card-col {
    margin: 1rem 0rem;
    width: 33.3%;
    @media screen and (max-width: $desktop) {
      width: 31%;
      margin: 0.75rem;
    }
    @media screen and (max-width: $largeTablet) {
      width: 45%;
      margin: 1rem;
    }
    @media screen and (max-width: $tablet) {
    }
    @media screen and (max-width: $mobile) {
      width: 100%;
      margin: 1rem 0.75rem;
    }

    #team-Card {
      margin: 0 1rem;
      border-radius: 15px;
      border: none;
      text-align: left;
      background: #f7f9fd;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black); /**for safari**/
      & :hover .img-con {
        cursor: pointer;
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
      }

      .img-con {
        overflow: hidden;
        max-height: 265px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transition: all 0.3s ease-in-out;

        img {
          width: 100%;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          object-fit: cover;
        }
      }

      .bodyText {
        padding: 1.5rem 2rem 2.5rem 2rem;
        color: $textDark;
        border-radius: 15px;
        &:hover {
          transform: none;
        }

        .title {
          @include TTCommon();
          font-size: 2.2rem;
          margin: 0;
          &:hover {
            transform: none;
          }
        }
        .content {
          @include Hind();
          font-size: 1rem;
          line-height: 1.5;
          margin: 0;
          padding-top: 0.5rem;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          min-height: 100px;
          &:hover {
            transform: none;
          }
        }
        .primary-btn {
          @include primary-btn();
          margin: 1.5rem 0;
          cursor: pointer;
          &:hover {
            transform: none;
          }
        }
      }
    }
  }
}
