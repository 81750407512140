/* --- Hind font from Google --- */
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;500;700&display=swap');


/* ---Open Sans font from Google --- */
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;500;700&family=Open+Sans:wght@300&display=swap');

/* --- TT Commons from Fonts.com --- */
@font-face {
    font-family:"TT Commons";
    src:url("../../fonts/6004dfed-29e5-4130-a90d-0de554ad3f2a.woff2") format("woff2"),url("../../fonts/25727e6e-2d59-480c-9330-b256fa239b66.woff") format("woff");
	font-style: normal;
	font-weight: 700;
}

$textDark:#05112A;
$textWhite: #FFFFFF;
$textAccent: #8EF8EE;
$bgDark : #1C222E;

$mobile: 576px; 
$tablet: 769px; 
$largeTablet:1080px;
$desktop: 1200px;



/**text**/
@mixin TTCommon()  {
    font-family: "TT Commons", Helvetica, Verdana, sans-serif;
    font-weight: 700;
}
@mixin Hind() {
    font-family: "Hind", Helvetica, Verdana, sans-serif;
    font-weight: 400;
}
@mixin OpenSans() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}


/***Learn More btn**/
@mixin primary-btn( ) {
    border: 2px solid #1C222E;
    color: #1C222E;
    background-color: transparent;
    border-radius: 40px;
    justify-content: center;
   align-items: center;
   padding: 0.3rem 1.25rem;
   font-weight: 700;
   font-size: 1rem;
   font-family: "Hind", Helvetica, Verdana, sans-serif;
   float: right;
   outline: none;
   box-shadow: none;
   &:hover{
    background: #1C222E;
    color:#FFFFFF;
    }
}
@mixin secondary-btn( ) {
    border: 2px solid #FEFEFE;
    color: #FEFEFE;
    background-color: transparent;
    border-radius: 40px;
    justify-content: center;
   align-items: center;
   padding: 0.5rem 2rem;
   font-weight: 700;
   font-size: 1rem;
   font-family: "Hind", Helvetica, Verdana, sans-serif;
   float: right;
   outline: none;
  box-shadow: none;
  &:hover{
      background-color: #FEFEFE;
      color: #1C222E;
  }
}


/** slide arrow**/
@mixin arrow() {
    width: 55px;
    height: 153px;
    background: url('../../images/slider-right.svg') center center no-repeat !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    right: 0 !important;
    cursor: pointer;
    &::before {
      display: none;
    }
}


@mixin leftArrow() {
    width: 55px;
    height: 153px;
    background: url('../../images/slider-left.svg') center center no-repeat !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    left: 0 !important;
    z-index: 1;
    cursor: pointer;
    &::before {
      display: none;
    }
}
