@import "../../components/scss/variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

.select {
  position: relative;
  overflow: hidden;
  display: block;
  float: right;
  width: 10rem;
  height: auto;
  @include Hind();
  color: $textAccent;
  font-size: 1rem;

  @media screen and (max-width: $mobile) {
    width: 9rem;
  }

  .option-item,
  .placeholder {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    cursor: pointer;
    text-align: right;
  }
  .placeholder {
    padding-right: 10px;
    right: 10px;

    &::before {
      content: "▼";
      position: absolute;
      top: 7px;
      right: -10px;
      color: $textAccent;
      font-size: 0.75rem;
    }
  }

  & > input {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    cursor: pointer;

    &:checked {
      div.options label.option .option-item {
        display: none !important;
      }
    }
    &:not(:checked) {
      z-index: 4;
      ~ label.option {
        > span.option-item {
          display: none;
        }
      }
    }
  }
  & > span.placeholder {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background-color: $bgDark;
  }

  label.option1 {
    input {
      &:checked + span.option-item {
        padding-top: 0 !important;
      }
    }
    span.option-item {
      padding-top: 10px !important;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  label.option6 span.option-item {
    padding-bottom: 10px !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  label.option {
    display: block;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    transition: all 1s ease-out;
    span.option-item {
      color: $textDark;
      background: #e8eefa;
      position: relative;
      z-index: 2;
      height: auto;
      text-align: left;
      padding: 0 10px;
      font-size: 1rem;
      line-height: 1.7;

      &:hover {
        background: #c5cad4;
      }
    }
    input[type="radio"] {
      display: none;
      &:checked ~ span.option-item {
        position: absolute;
        display: block;
        z-index: 3;
        top: 0px;
        font-size: 1rem;
        background: $bgDark;
        box-shadow: none;
        color: inherit;
        width: 100%;
        text-align: right;
        right: 10px;
        &::before {
          content: "▼";
          position: absolute;
          top: 6px;
          right: -10px;
          color: $textAccent;
          font-size: 0.75rem;
        }
      }
    }
  }
}
