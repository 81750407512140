@import "../../../components/scss/variables.scss";
@import "../../home/main.scss";

.bg {
  background: linear-gradient(135deg, #007899 0%, #005c79 60.42%, #094151 100%);

  .bg-con {
    max-width: 1440px;
    margin: 0 auto;
    padding: 2rem;

    #team-detail {
      display: flex;
      justify-content: start;
      @media screen and (max-width: $largeTablet) {
        display: grid;
        margin: 0 auto;
      }

      .detail-img {
        width: 35%;
        @media screen and (max-width: $largeTablet) {
          width: 100%;
          margin: 0 auto;
          padding-bottom: 1.5rem;
        }
        img {
          border-radius: 15px !important;
          width: 100%;
          max-width: 500px;
          height: auto;
          object-fit: cover;
          @media screen and (max-width: $largeTablet) {
            max-width: none;
          }
        }
      }

      .detail-text {
        width: 65%;
        text-align: left;
        margin-left: 3rem;
        @media screen and (max-width: $largeTablet) {
          width: 100%;
          margin-left: 0;
          margin: 0 auto;
        }
        h1 {
          @include TTCommon();
          font-size: 2.5rem;
          color: $textWhite;
          margin-top: 0;
        }
        p {
          @include Hind();
          font-weight: 400;
          color: $textWhite;
          font-size: 1.25rem;
          margin-top: 1rem;
        }
      }
    }
  }
}

.slide-container {
  padding-bottom: 5rem;
}
