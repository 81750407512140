@import "./variables.scss";

.feedback-btn-container {
    position: fixed;
    top: 66%;
    right: 0;
    cursor: default;
}
.feedback-btn {
    padding: 0.5rem 0;
    display: block;
    line-height: 2em;
    writing-mode: vertical-rl;
    background-color: #fff;
    color: #000;
    opacity: 0.5;
    transition: all 0.25s ease-out;
    text-decoration: none;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.modal-grid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #1c222edd;
    backdrop-filter: blur(10px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr auto 1fr;
    color: #000;
    z-index: 100;

    .modal-container {
        padding: 1rem;
        position: relative;
        grid-column: 2;
        grid-row: 2;
        background: linear-gradient(135deg, #01cbb7 0%, #00796d 60.42%, #00655b 100%);
        border-radius: 0.5rem;
        color: #000;
        box-shadow: 0 1rem 2rem #0009;

        textarea {
            display: block;
            margin: 0 0 1rem;
            padding: 0.25rem 0.5rem;
            width: 100%;
            height: 10rem;
            border: none;
            border-radius: 0.5rem;
            font-size: 1rem;
            line-height: 1.5;
            @include Hind();
        }

        .close-btn {
            padding: 9px;
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            border: none;
            background-color: #fff0;
            transition: background-color 0.2s ease;

            svg {
                display: block;
                fill: #fff8;
                transition: fill 0.2s ease;
            }

            &:hover,
            &:focus {
                background-color: #fff8;

                svg {
                    fill: #333;
                }
            }
        }
    }

    h2 {
        margin-top: 0;
        color: #fff;
        @include TTCommon() ;
    }

    p {
        margin-bottom: 2rem;
        color: #fff;
    }

    .btn,
    button {
        margin: 0 auto;
        padding: 9px 24px 8px;
        display: block;
        appearance: none;
        font-family: "Hind", sans-serif;
        font-weight: 700;
        font-size: 0.88rem;
        line-height: 1;
        border: 2px solid #fff;
        border-radius: 40px;
        background-color: transparent;

        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus {
            background-color: #fff;
            color: #05112a;
        }
    }
}
