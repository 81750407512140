@import "../../components/scss/variables.scss";

main {
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem 0;
  @media screen and (max-width: $tablet) {
    padding: 1rem;
  }

  /**go back to home button**/
  .goHome {
    color: $textAccent;
    @include Hind();
    cursor: pointer;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    padding-left: 2.5rem;
    @media screen and (max-width: $tablet) {
      padding-left: 1rem;
    }

    img {
      vertical-align: middle;
      box-sizing: border-box;
      margin-right: 1rem;
    }
  }

  .main-title {
    margin: 3.5rem 0 1rem 0;
    padding: 0 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @media screen and (max-width: $mobile) {
      justify-content: end;
      flex-direction: column;
      padding: 0;
    }

    h1 {
      color: $textWhite;
      @include TTCommon();
      font-size: 2.6rem;
      font-weight: 700;
      padding: 0;
      margin: 0;
      @media screen and (max-width: $tablet) {
        width: 80%;
        font-size: 2.25rem;
        margin: 0;
      }
    }
  }
  .accent {
    color: $textAccent;
    padding: 0;
    font-size: 1rem;
    text-align: right;
    align-self: flex-end;
    cursor: pointer;
    @include Hind();
  }
}

section {
  @media screen and (max-width: $mobile) {
    padding: 0;
  }
}

/**dropdown section*/
#jobs-section {
  display: inline;

  .dropdown-section {
    margin-top: -2.75rem;
    @media screen and (max-width: $tablet) {
      margin-top: 0;
    }
    .accent {
      float: right;
      margin-left: 1.5rem;
    }
  }
}
