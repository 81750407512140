@import "../../components/scss/variables.scss";

.arrow{
   cursor: pointer;
   margin-left: 1.5rem;
 }

section#careerpath-page {
   width: 100%;
   margin: 0 auto;

   @media screen and ( max-width:1240px){
      padding: 0 1rem;
      padding-bottom: 3rem;
      }
    @media screen and ( max-width:$largeTablet){
      padding: 0 0.5rem;
      padding-bottom: 3rem;
    }
    @media screen and ( max-width:$mobile){
      padding:1rem;
    }

    .career-col{
      display: inline-block;
      margin: 2rem 1rem;
      width: 30%;
      @media screen and ( max-width: 1240px){
         margin:0.75rem;
         }
         @media screen and ( max-width: $largeTablet){
           width: 45%;
         }
         @media screen and ( max-width: $tablet){
           margin:0.5rem;
         }
         @media screen and ( max-width: $mobile){
           width: 90%;
           margin: 1rem 0.5rem;
         }
 
      #careerCard{
         border-radius: 15px ;
         border: none;
         text-align: left;
         background: #F7F9FD;
         overflow: hidden;


       & :hover img{
         cursor: pointer;
         transform: scale(1.05);
         -webkit-transform: scale(1.05);
         -moz-transform: scale(1.05);
         -ms-transform: scale(1.05);
         -o-transform: scale(1.05);
       }
             img{
               width: 100%;
               object-fit: cover;
               max-height: 265px;
               border-top-left-radius: 15px;
               border-top-right-radius: 15px;
               transform: scale(1);
               -webkit-transform: scale(1);
               -moz-transform: scale(1);
               -ms-transform: scale(1);
               -o-transform: scale(1);
               transition: all 0.3s ease-in-out; 
               }
           }
 
            .bodyText{
                @include Hind();
                font-weight: 400;
                font-size: 1rem;
                padding: 0.5rem 2rem 2.5rem 1.75rem;
                color: $textDark;
                border-radius: 15px;
                line-height: 1.3;

                 h2{
                  @include TTCommon();
                  font-size: 2.2rem;
                  margin: 0.875rem 0; 
                  letter-spacing:-1px ;
                  white-space:nowrap;
                  text-overflow:ellipsis;
                  @media screen and ( max-width: $tablet){
                    white-space:normal;
                    letter-spacing:0 ;
                    line-height: 1;
                  }
                }  
                 .view-more, .view-less{
                 color:#007899 ;
                 white-space: normal;
                 font-size: 1rem;
                   }
               }
 
   .primary-btn{
       @include primary-btn();
       margin: 1.5rem 0;
       padding: 0.5rem 1.5rem;
       }
   
 

   }


 }




