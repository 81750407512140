@import "../../components/scss/variables.scss";

/**join page*/

.join-control {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

/**job detail page**/
.job-bg {
  background: linear-gradient(135deg, #01cbb7 0%, #00796d 60.42%, #00655b 100%);

  .width-control {
    max-width: 1440px !important;
    margin: 0 auto;

    #job-detail {
      margin-left: 0;
      padding: 1.75rem 1rem;
      @media screen and (max-width: 1024px) {
        padding: 1.5rem 1.5rem;
      }
      .detail-text {
        div {
          text-align: left;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media screen and (max-width: $tablet) {
            display: grid;
          }
        }
        span,
        p {
          @include Hind();
          font-weight: 700;
          font-size: 1.125rem;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          color: #00655b;
          margin: 0;
        }
        h1 {
          @include TTCommon();
          font-size: 3.125rem;
          color: $textWhite;
          margin: 0.25rem 0;
          @media screen and (max-width: $mobile) {
            font-size: 2.85rem;
            line-height: 1;
          }
        }
        .secondary-btn {
          @include secondary-btn();
        }
      }
    }
  }
}

#job-main {
  max-width: 1440px;
  margin: 0 auto;

  .job-description {
    margin: 2.5rem 0;
    padding: 0 1rem;
    color: $textWhite;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5;
    @include Hind();
    a {
      color: $textAccent;
    }
  }
}
