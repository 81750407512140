@import "../../components/scss/variables.scss";
@import "../home/main.scss";
  
.arrow{
  cursor: pointer;
  margin-left: 1.5rem;
}

section#value-page{
  width: 100%;
  margin: 0 auto;

  @media screen and ( max-width:1240px){
    padding: 0 1rem;
    padding-bottom: 3rem;
    }
  @media screen and ( max-width:$largeTablet){
    padding: 0 0.5rem;
    padding-bottom: 3rem;
  }
  @media screen and ( max-width:$mobile){
    padding:1rem;
  }

  .value-col{
    display: inline-block;
    margin: 2rem 1rem;
    padding: 0;
    width: 30%;
  @media screen and ( max-width: 1240px){
    margin:0.5rem;
    }
    @media screen and ( max-width: $largeTablet){
      width: 43%;
    }
    @media screen and ( max-width: $tablet){
      margin:1rem;
    }
    @media screen and ( max-width: $mobile){
      width: 90%;
      margin:0.5rem 0;
    }
}

    #valueCard{
      border-radius: 15px ;
      text-align: left;
      background-color: #383E4A;
      &:hover{
        background: #4F5560;
      }
      .bodyText{
        padding: 1.75rem 2rem;
            h3{
               @include TTCommon();
               font-size: 2.25rem;
               color: $textWhite;
               margin:0.5rem 0;
               }  
               .value-content{
                @include Hind();
                color: $textWhite;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.4;
              }
            }
      }


    }
