@import "../../components/scss/variables.scss";

.box {
  width: 100%;
  padding: 0 0 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: $tablet) {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
}

#cardBox {
  width: 50%;
  margin-right: 1rem;
  border-radius: 15px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black); /**for safari**/
  @media screen and (max-width: $tablet) {
    margin-top: 1rem !important;
    width: 100%;
    margin: 0 auto;
  }
}

.mask {
  max-height: 280px;
  border: none;
  position: relative;
  text-align: left;
  &:hover img {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
  }
  @media screen and (max-width: $mobile) {
    height: 400px;
  }
  .image {
    width: 100%;
    object-fit: contain;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: $mobile) {
      height: 400px;
      object-fit: cover;
    }
  }
}
.body {
  margin: 0 auto;
  width: 100%;
  position: absolute;
  bottom: 0;

  .modal-box {
    margin: 0 auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    min-height: 300px;
    @media screen and (max-width: $mobile) {
      height: 400px;
    }
  }

  .title {
    @include TTCommon();
    font-size: 2.25rem;
    color: $textWhite;
    margin: 0;
    padding: 0 2rem;
    @media screen and (max-width: $largeTablet) {
      font-size: 2rem;
    }
    @media screen and (max-width: $mobile) {
      font-size: 1.75rem;
    }
  }
  .sub-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem 0.75rem 2rem;
    @media screen and (max-width: $mobile) {
      display: block;
    }

    .content {
      @include Hind();
      font-size: 1rem;
      font-weight: 400;
      color: $textWhite;
      line-height: 1.4;
      width: 70%;
      @media screen and (max-width: $mobile) {
        width: 100%;
        margin: 0;
      }
    }

    .secondary-btn {
      cursor: pointer;
      @include secondary-btn();
      z-index: 1;
      @media screen and (max-width: $mobile) {
        display: none;
      }
    }
  }
}
