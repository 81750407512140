@import "../../components/scss/variables.scss";

      /**Our Value**/
   #valueSlide{
        max-width: 250px;
        width: 100%;
        padding: 0.5rem 0.4rem 0.25rem 0.4rem;
        border-radius: 15px ;
        border: none;
        text-align: left;
        background: #383E4A;
        @media screen and ( max-width: $tablet){
          max-width: 300px;
          margin: 0 1rem;
         }
         @media screen and ( max-width: $mobile){
          max-width: 400px;
          margin: 0 1.5rem;
         }
         @media screen and ( max-width: 395px){
          max-width: 300px;
         }
        &:hover{
          background: #4F5560;
        }

       
        .value-body{
            font-family: "Hind", Helvetica, Verdana, sans-serif;
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem;
            color: $textWhite;

            .value-title{
                font-family: "TTCommons", Helvetica, Verdana, sans-serif;
                font-weight: 700;
                font-size: 1.25rem;
                color:$textWhite;
            }

            .value-content{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;      
               }
        }
 }

  #small-slide {
    .slick-next {
      @include arrow();
    }
    .slick-prev{
        @include leftArrow();
    }
  
  
}
     